import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  RegisterUserData,
  LoginUserData,
  EditUserData,
  ChangePasswordData,
  RoleUgrade,
  ResetToken,
  GoogleLoginPayload,
} from "./authInterfaces";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
const USER_API_URL = `${backendUrl}/api/`;

// Create authApi
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: USER_API_URL,
    credentials: "include", // For handling cookies/session tokens
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // REGISTER USER
    register: builder.mutation({
      query: (userData: RegisterUserData) => ({
        url: "auth/signup",
        method: "POST",
        body: userData,
      }),
    }),

    // SEND VERIFICATION EMAIL AFTER SIGN UP
    sendVerificationEmail: builder.mutation({
      query: () => ({
        url: "auth/email/sendverify-emailtoken",
        method: "POST",
      }),
    }),

    // CONFIRM ACCOUNT FROM EMAIL LINK
    verifyAccountFromEmail: builder.mutation({
      query: ({ verifyAccountToken }: { verifyAccountToken: string }) => ({
        url: `auth/verifyuseraccount/${verifyAccountToken}`,
        method: "PATCH",
      }),
    }),

    // LOG IN USER
    signin: builder.mutation({
      query: (userData: LoginUserData) => ({
        url: "auth/signin",
        method: "POST",
        body: userData,
      }),
    }),

    // SIGNOUT USER || LOGOUT
    signOut: builder.mutation({
      query: () => ({
        url: "auth/signout",
        method: "GET", // Ensure this matches the backend
      }),
    }),

    // EMAIL TOKEN || FORGET PASSWORD
    forgetPasswordEmailResetToken: builder.mutation({
      query: (data: ResetToken) => ({
        url: "auth/email/forgotpasswordresettoken",
        method: "POST",
        body: data,
      }),
    }),

    // RESET PASSWORD FROM EMAIL TOKEN
    resetPasswordFromEmail: builder.mutation({
      query: ({
        data,
        resetEmailToken,
      }: {
        data: ResetToken;
        resetEmailToken: string;
      }) => ({
        url: `auth/resetpassword/${resetEmailToken}`,
        method: "PATCH",
        body: data,
      }),
    }),

    // CHECK USER LOGIN STATUS
    getLoginStatus: builder.query({
      query: () => "auth/isloggedin",
    }),

    // GET USER PROFILE
    getUserProfileService: builder.query({
      query: () => "user/getuser",
    }),

    // UPDATE USER PROFILE
    updateMyProfileService: builder.mutation({
      query: (userData: EditUserData) => ({
        url: "user/updateUser",
        method: "PATCH",
        body: userData,
      }),
    }),

    // CHANGE USER PASSWORD
    changeMyPasswordService: builder.mutation({
      query: (userData: ChangePasswordData) => ({
        url: "user/changemypassword",
        method: "PATCH",
        body: userData,
      }),
    }),

    // DELETE MY ACCOUNT || USER
    deleteMyAccount: builder.mutation({
      query: () => ({
        url: "user/deletemyaccount/",
        method: "DELETE",
      }),
    }),

    // ADMIN PANEL || GET ALL USERS
    getAllUserData: builder.query({
      query: () => "user/getusers",
    }),

    // ADMIN PANEL || UPGRADE USER ROLE
    upgradeUser: builder.mutation({
      query: (userData: RoleUgrade) => ({
        url: "user/upgraderole",
        method: "PATCH",
        body: userData,
      }),
    }),

    // ADMIN PANEL || DELETE USER
    deleteUser: builder.mutation({
      query: (userID: string) => ({
        url: `user/delete/${userID}`,
        method: "DELETE",
      }),
    }),

    // THIRD PARTY - GOOGLE AUTHENTICATION
    loginWithGoogle: builder.mutation({
      query: (userToken: GoogleLoginPayload) => ({
        url: "auth-google/callback",
        method: "POST",
        body: userToken,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useRegisterMutation,
  useSendVerificationEmailMutation,
  useVerifyAccountFromEmailMutation,
  useSigninMutation,
  useSignOutMutation,
  useForgetPasswordEmailResetTokenMutation,
  useResetPasswordFromEmailMutation,
  useGetLoginStatusQuery,
  useGetUserProfileServiceQuery,
  useUpdateMyProfileServiceMutation,
  useChangeMyPasswordServiceMutation,
  useDeleteMyAccountMutation,
  useGetAllUserDataQuery,
  useUpgradeUserMutation,
  useDeleteUserMutation,
  useLoginWithGoogleMutation,
} = authApi;
